<template>
  <layoutContainer>
    <div class="wrap-container">
      <div class="enter-container">
        <div class="mainMsgBox">
          <div class="button-box">
            <button v-if="!UserInfo.teamId && UserInfo.employeeId" @click="teamApply()">
              <img src="/img/companyAdd.png" alt="" /> 申请入团
            </button>
            <button v-if="UserInfo.teamId == id" @click="teamQuit()">
              <img src="/img/zhuceyaoqing.png" alt="" /> 退出团队
            </button>
            <button v-if="isManager || UserInfo.isManage" @click="teamDissolve()">
              <img src="/img/X.png" alt="" /> 解散团队
            </button>
            <button v-if="isManager || UserInfo.isManage" class="button-right" @click="editCompanyCase">
              <img src="/img/编辑.png" alt="" /> 修改信息
            </button>
          </div>
          <div class="logo-box">
            <img :src="detailOptions.smallLogoPicUrl" alt="logo" />
          </div>
          <div class="detail-box">
            <h4>{{ detailOptions.name }}</h4>
            <p>
              <span>成立时间 ：</span>
              <b>{{ detailOptions.establishDate && detailOptions.establishDate | formatTimeFilter('YYYY-MM-DD') }}</b>
            </p>
            <p>
              <span>所在地区 ：</span> <b>{{ detailOptions.provinceId | AreaListFilter }}</b>
            </p>
            <p>
              <span>团队人数 ：</span> <b>{{ detailOptions.memberNum || 0 }}</b>
            </p>
            <p>
              <span>承接开发 ：</span>
              <domaintag
                v-for="item in detailOptions.businessIdList"
                :key="item"
                type="info"
                :text="item | businessIdFilter"
              ></domaintag>
            </p>
          </div>
        </div>
        <hr />
        <h4>团队简介</h4>
        <div class="text">{{ detailOptions.introduction }}</div>
      </div>
      <div class="member-box">
        <h4 clang="member-box-title">成员</h4>
        <el-timeline style="margin-top:30px">
          <el-timeline-item color="#37C2BC" v-if="managerList.length">
            <h4 class="min-class-title">负责人</h4>
            <!-- <el-card> -->
            <div class="member-box-container ">
              <teamMemberItem
                v-for="(item, i) in managerList"
                :key="i"
                :detail="item"
                :isManager="isManager"
                :showManagerTag="true"
                @getTeamDetail="getTeamDetail"
              ></teamMemberItem>
            </div>
            <!-- </el-card> -->
          </el-timeline-item>
          <el-timeline-item color="#37C2BC" v-if="ProductManager.length">
            <h4 class="min-class-title">产品</h4>
            <div class="member-box-container ">
              <teamMemberItem
                v-for="(item, i) in ProductManager"
                :key="i"
                :detail="item"
                :isManager="isManager"
                @getTeamDetail="getTeamDetail"
              ></teamMemberItem>
            </div>
          </el-timeline-item>
          <!-- <el-timeline-item color="#37C2BC" v-if="TechniqueManager.length">
            <h4>技术负责人</h4>
            <div>
              <teamMemberItem
                v-for="(item, i) in TechniqueManager"
                :key="i"
                :detail="item"
                :isManager="isManager"
                @getTeamDetail="getTeamDetail"
              ></teamMemberItem>
            </div>
          </el-timeline-item> -->
          <el-timeline-item color="#37C2BC" v-if="nomalMemberList.length">
            <h4 class="min-class-title">技术</h4>
            <div class="member-box-container ">
              <teamMemberItem
                v-for="(item, i) in nomalMemberList"
                :key="i"
                :detail="item"
                :isManager="isManager"
                @getTeamDetail="getTeamDetail"
              ></teamMemberItem>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div class="teamApplyBox" v-if="teamApplyList.length && (isManager || UserInfo.isManage)">
        <h4>入团申请</h4>
        <ul class="teamApplyItem" v-for="(item, i) in teamApplyList" :key="i">
          <p class="avaderImgbox">
            <avaderImg
              :imgUrl="item.employee.smallHeadPicUrl"
              :size="55"
              :sex="item.employee.gender == 1 ? true : false"
            ></avaderImg>
          </p>
          <li class="nickName">
            <span>{{ item.employee.nickName }}</span>
          </li>
          <li class="date">
            {{ item.employee.currentCompany }}丨 {{ item.employee.currentStation | EmployeeStationListFilter }} 丨
            {{ item.employee.workYears }}年
          </li>
          <li class="applytime">{{ item.applyTime | formatTimeFilter }} 申请</li>
          <li>
            <button class="button blue" @click="teamApplyAgree(item.employee.id)">同意</button>
            <button class="button red" @click="teamApplyReject(item.employee.id)">拒绝</button>
            <button class="button info" @click="teamApplyDelete(item.employee.id)">删除</button>
          </li>
        </ul>
      </div>
    </div>
  </layoutContainer>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import teamMemberItem from './teamMemberItem'

import {
  getTeamDetailApi,
  getTeamApplyListApi /* 申请入团列表 */,
  teamApplyApi /* 申请入团 */,
  teamQuitApi /* 退出团队 */,
  teamDissolveApi /* 解散团队 */,
  teamApplyAgreeApi /* 申请入团同意 */,
  teamApplyRejectApi /* 申请入团拒绝 */,
  teamApplyDeleteApi /* 申请入团删除 */
} from '../../api/team'

export default {
  components: {teamMemberItem},
  computed: {
    ...mapState(['UserInfo', 'AreaList', 'BusinessList', 'EducationList', 'EmployeeDomainList']),
    isManager() {
      return (
        this.managerList.some(item => item.managerInfo.employeeId == this.UserInfo.employeeId) || this.UserInfo.isManage
      )
    }
  },

  created() {
    const ss = this.$route.query?.detail
    this.detailOptions = ss ? JSON.parse(ss) : {}
    console.log(
      '%c 路由携带团队详情....',
      'color: red; font-style: italic',
      JSON.stringify(this.detailOptions, null, 2)
    )
    // this.id = this.detailOptions?.id
    this.id = this.$route.params.id
    if (this.id) {
      this.getTeamDetail()
      this.getTeamApplyList()
    }
  },
  data() {
    return {
      id: 1,
      // isManager: false,
      teamApplyList: [
        // {
        //   employee: {
        //     id: 1,
        //     userId: 1,
        //     realName: '特朗普',
        //     nickName: '阿迪达斯',
        //     headPicUrl: null,
        //     smallHeadPicUrl: null,
        //     currentCompanyVisible: null,
        //     status: 1,
        //     gender: 1,
        //     settleTime: '2020-10-02T14:46:31.000+00:00',
        //     birthDay: '2020-10-13T16:00:00.000+00:00',
        //     area: 1,
        //     education: 1,
        //     workYears: 3,
        //     occupation: 1,
        //     currentStation: 2,
        //     currentCompany: '美团',
        //     introduction:
        //       '毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东毛泽东'
        //   },
        //   applyTime: '2020-10-14T15:06:57.000+00:00'
        // }
      ],
      detailOptions: {},
      memberList: [
        //       {
        // "id": 1,  /** * 自增主键 */
        // "teamId": 1,  /** * 团队id */
        // "employeeId": 1,   /** * 个人id */
        // "userId": 1,    /** * 用户id */
        // "isManager": 1,    /** * 是否负责人。1:是；0:否。 */
        // "isTeamManager": 1,    /** * 是否团队负责人 */
        // "isProductManager": 1,    /** * 是否产品负责人 */
        // "isTechniqueManager": 1,     /** * 是否技术负责人 */
        // "joinTime": "2020-10-13T12:43:27.000+00:00"    /** * 入团时间 */
        //       }
      ],
      employeeVoList: [],
      managerList: [], // 负责人
      TeamManagerList: [], //人列表
      ProductManager: [], // 产品列表
      TechniqueManager: [], // 技术负责人列表
      nomalMemberList: [] // 普通成员列表
    }
  },
  methods: {
    ...mapMutations(['ChangeUserInfo']),
    ...mapActions(['ChangeUserInfoActions']),
    async getTeamDetail() {
      getTeamDetailApi({teamId: this.id}).then(res => {
        // console.log('%c getTeamDetailApi....', 'color: red; font-style: italic', JSON.stringify(res.data, null, 2))
        const {employeeVoList, memberList, teamVo} = JSON.parse(JSON.stringify(res.data))
        this.detailOptions = teamVo ? teamVo : {}
        this.employeeVoList = employeeVoList.map(item => {
          return {...item, managerInfo: memberList.filter(el => el.employeeId == item.id)[0]}
        })
        console.log('%c 格式化参数....', 'color: red; font-style: italic', this.employeeVoList)

        this.managerList = [
          ...new Set([
            ...this.employeeVoList.filter(item => item.managerInfo.isTeamManager),
            ...this.employeeVoList.filter(item => item.managerInfo.isTechniqueManager),
            ...this.employeeVoList.filter(item => item.managerInfo.isProductManager)
          ])
        ]
        this.TeamManagerList = this.employeeVoList.filter(item => item.managerInfo.isTeamManager)
        this.ProductManager = this.employeeVoList.filter(item => item.occupation == 2)
        this.TechniqueManager = this.employeeVoList.filter(item => item.managerInfo.isTechniqueManager)
        this.nomalMemberList = this.employeeVoList.filter(item => {
          return !item.managerInfo.isManager && item.occupation !== 2
          // !item.managerInfo.isProductManager &&
          // !item.managerInfo.isTeamManager &&
          // !item.managerInfo.isTechniqueManager
        })
      })
    },
    getTeamApplyList() {
      if (!this.UserInfo?.id) return
      getTeamApplyListApi({teamId: this.id}).then(res => {
        this.teamApplyList = res.data || []
        console.log('%c 申请入团列表....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
      })
    },
    teamApplyAgree(employeeId) {
      teamApplyAgreeApi({teamId: this.id, employeeId})
        .then(res => {
          console.log('%c 申请入团同意....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          // this.$dialog.toast('同意申请入团成功')
          this.getTeamApplyList()
          this.getTeamDetail()
        })
        .catch(err => {
          console.log('%c 申请入团同意....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    teamApplyReject(employeeId) {
      teamApplyRejectApi({teamId: this.id, employeeId})
        .then(res => {
          console.log('%c 申请入团驳回....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.getTeamApplyList()
        })
        .catch(err => {
          console.log('%c 申请入团驳回....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    teamApplyDelete(employeeId) {
      teamApplyDeleteApi({teamId: this.id, employeeId})
        .then(res => {
          console.log('%c 申请入团驳回删除....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
          this.getTeamApplyList()
        })
        .catch(err => {
          console.log('%c 申请入团驳回删除....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },
    teamApply() {
      this.$confirm('确定要申请加入此团队吗？').then(() => {
        teamApplyApi({teamId: this.id, employeeId: this.UserInfo.employeeId})
          .then(res => {
            if (res.data) {
              this.$message(res.data)
            } else {
              this.$message('申请入团成功')
            }
            console.log('%c 申请入团....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
            this.getTeamApplyList()
          })
          .catch(err => {
            console.log('%c 申请入团....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
          })
      })
    },
    teamQuit() {
      this.$confirm('确认退出团队吗？')
        .then(_ => {
          teamQuitApi({teamId: this.id, employeeId: this.UserInfo.employeeId})
            .then(res => {
              if (res.data) {
                this.$message(res.data)
              } else {
                console.log('%c 退出团队....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
                // this.$dialog.toast('退出成功')
                this.$message('您已退出团队')
                this.ChangeUserInfoActions()
                this.$router.go(-1)
              }
            })
            .catch(err => {
              console.log('%c 退出团队....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
            })
        })
        .catch(_ => {})
    },
    teamDissolve() {
      this.$confirm('确认解散团队吗？')
        .then(_ => {
          teamDissolveApi({teamId: this.id})
            .then(res => {
              if (res.data) {
                this.$message(res.data)
              } else {
                console.log('%c 解散团队....', 'color: red; font-style: italic', JSON.stringify(res, null, 2))
                this.$message(res.data || '团队已解散')
                this.ChangeUserInfoActions()
                this.$router.go(-1)
              }
            })
            .catch(err => {
              console.log('%c 解散团队....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
            })
        })
        .catch(_ => {})
    },
    editCompanyCase() {
      // this.$router.push('/companyEnter')
      this.$router.push({
        path: `/teamEnter`,
        query: {
          id: this.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.teamApplyBox {
  padding: 40px;
  background: #ffffff;
  .teamApplyItem {
    display: flex;
    // border: 1px solid #000;
  }
  h4 {
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 30px;
  }
  ul {
    display: flex;
    width: 100%;
    height: 100px;
    line-height: 100px;
  }

  .avaderImgbox {
    margin-top: 15px;
    margin-right: 30px;
  }
  li.nickName {
    margin-right: 60px;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  li.date {
    min-width: 250px;
    margin-right: 80px;
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
  .applytime {
    margin-top: 35px;
    margin-right: 70px;
    width: 245px;
    height: 26px;
    line-height: 26px;
    background: #f6f6f6;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    text-align: center;
    border-radius: 3px;
  }
  .button {
    width: 62px;
    height: 28px;
    margin-left: 10px;
    border: none;
    outline: none;
    color: white;
    border-radius: 3px;
  }
  .blue {
    background: #5ad3cd;
  }
  .info {
    background: #d1d1d1;
  }
  .red {
    background: #ff724c;
  }
}

.enter-container {
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px 40px;

  .mainMsgBox {
    position: relative;
    display: flex;
    .logo-box {
      margin-right: 40px;
      img {
        width: 300px;
        height: 225px;
        border-radius: 5px;
      }
    }

    .button-box {
      position: absolute;
      right: 0;
      top: 0;
      button {
        margin-left: 20px;
        width: 124px;
        height: 40px;
        border-radius: 4px;
        border: 2px solid #5ad3cd;

        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #5ad3cd;

        background: #ffffff;
        img {
          height: 20px;
          vertical-align: middle;
        }
      }
    }
    .detail-box {
      h4 {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      p {
        height: 45px;
        line-height: 45px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        span {
          color: #999999;
        }
      }
    }
  }
  hr {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  h4 {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .text {
    margin-top: 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 36px;
  }
}

.member-box {
  width: 1200px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 50px 40px;
  h4.member-box-title {
    height: 30px;
    font-size: 22px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 30px;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 40px;
  }
}

.wrap-container {
  // /deep/ .el-card__body {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  /deep/ .el-timeline-item__tail {
    border-left: 1px solid #37c2bc;
  }
  /deep/ .el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
    display: block;
  }
}

hr {
  border: 1px solid #dedede;
  border-top: none;
}

.min-class-title {
  position: relative;
  top: -14px;
  padding-left: 20px;
}

.member-box-container {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  // justify-content: space-around;
  > div:nth-child(2n) {
    margin-left: 130px;
  }
}
</style>
